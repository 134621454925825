/* You can add global styles to this file, and also import other style files */
@import '@seech/core-ng/core';
@import 'base/overrides';
@import '@seech/core-ng/theme';
@import 'theme';
@import '@seech/icons-ng';

:root {
    --product-primary: #9A0DB1;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.texts-elipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@layer utilities {
     .text-product-primary {
        color: var(--product-primary);
    }
    .bg-product-primary {
        background-color: var(--product-primary);
    }
}

.collapse {
    visibility: visible !important;
}