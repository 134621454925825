@use 'mixins/breakpoints' as breakpoint;
@import './variables';
@import './index';

$primary: $primary;
$primary-dark: $primary-dark;
$secondary: $secondary;
$success: #009429;
$danger: #dc4c64;
$warning: #ef790d;
$info: #54b4d3;
$light: #fbfbfb;
$dark: #333333;
$grey-light: #666666;
$tertiary: #02C414;

$primary-font: 'Poppins';

$seech-layout-width: 80%;
$seech-layout-minimize-width: 3.5rem;
$seech-layout-item-space: 0;
$seech-layout-item-icon-right: 1rem;
$seech-layout-item-icon-left: 0;
$seech-layout-item-selected-overlay: $primary;
$seech-layout-bottom-nav-height: $mobile-bottom-nav-height;
$seech-layout-header-height: $header-height;
$seech-layout-mobile-header-height: $mobile-header-height;