// colors
$purple-100: #0b032d;
$primary-dark: #052406;
$secondary: #077d0b;
$secondary-light: #009429;
$primary: #2169DD;
$orange-100: #ef790d;
$orange-200: #ff8f6b;
$orange-300: #ffd8b4;
$white-100: #ffffff;
$black-100: #000000;
$grey-100: #dedede;
$grey-200: #101928;
$text-100: #030229;
$text-200: #2b3865cc;
$text-300: #97a2b0;
$border-100: #ccd4de;
$text-success: #0cc558;
$text-warning: #f4a229;
$text-danger: #ff4747;
$text-danger-100: #ef360d;
$header-height: 4rem;
$mobile-header-height: 3.5rem;
$bg-success: #077d0b;
$bg-success-100: #2fc17b1a;
$black-200: #333333;
$grey-300: #D0DDF8;
$grey-light: #666666;
$le-primary: #9A0DB1;
$product-primary: var(--product-primary, #9A0DB1);

$bg-success-200: rgba(204, 255, 225, 0.4);
$bg-error: rgba(255, 71, 71, 0.15);
$bg-warning: rgba(244, 162, 41, 0.15);

$bg-purple: #28147c1a;
$bg-purple-200: #713bdb1a;
$blue-500: #07152C;
$bg-danger: #ef360d1a;
$bg-orange: #ef790d1a;
$purple-300: #28147c;
$purple-200: #6f52ed;
$blue-100: #0d82ef;

// Breakpoints
$xsmall: 320px; // Extra small custom
$small: 400px; // Small custom
$xs: 576px; // Extra small devices (phones)
$sm: 600px; // Small devices (tablets)
$md: 768px; // Medium devices (small laptops)
$lg: 769px; // Large devices (laptops/desktops)
$xl: 1280px; // Extra large devices (desktops)
$xxl: 1600px; // XXL screens (large desktops)

$header-height: 4rem;
$mobile-bottom-nav-height: 67px;