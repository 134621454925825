@use 'sass:math';

@import './base/variables';

@mixin styleFromBreakpoint($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin styleBetweenBreakpoints($lowerbound, $upperbound) {
  @media only screen and (min-width: $lowerbound) and (max-width: $upperbound) {
    @content;
  }
}

@mixin pxToRem($size) {
  font-size: math.div($size, 16px) * 1rem !important;
}

@mixin setDistinctSize($width, $height) {
  width: $width;
  height: $height;
}

@mixin setRoundedCorner($size) {
  @include setDistinctSize($size, $size);
  border-radius: 50%;
}

@mixin custom-scrollbar($scrollbar-color) {
  &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0.25rem;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollbar-color;
    }
  }
}